
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { AsyncStorage } from '@core/services/AsyncStorage';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { APIResponse } from '@shared/types/APIResponse';
import { StripeAccount, StripeData, StripeAccountCreationData } from '@core/data/stripe'
import { MonerisAccount, MonerisData } from '@core/data/moneris'
import { DebugService as debug } from "@core/services/debug.service";
import { Objects } from '@carwashconnect/cwc-core-js';
import { AuthService } from '@core/services/auth/auth.service';

export { StripeAccount }

@Injectable()
export class ProcessingService {

  private _stripe: AsyncStorage<StripeAccount>;
  private _monerisAccounts: AsyncStorage<MonerisAccount>;
  private _targetClientId: string;
  private _activeStripeClientId: string;
  private _activeMonerisClientId: string;
  private _lastUsedMonerisId: number = 0;

  constructor(
    private _http: HttpClient,
    private _clientsService: ClientsService,
    private _auth: AuthService
  ) {
    this._initializeStorage();
  }

  private _initializeStorage() {

    //Prepare stripe storage
    this._stripe = new AsyncStorage(this._http);
    this._stripe._setAddFunction(this._addStripe());
    this._stripe._setElementComparisonFunction(this._stripeComparison);
    this._stripe._setSaveActiveElementFunction(this._saveStripe());
    this._stripe._setUpdateFunction(this._updateStripes());

    //Prepare moneris storage
    this._monerisAccounts = new AsyncStorage(this._http);
    this._monerisAccounts._setAddFunction(this._saveMonerisAccounts());
    this._monerisAccounts._setElementComparisonFunction(this._monerisAccountComparison);
    this._monerisAccounts._setSaveActiveElementFunction(this._saveMonerisAccounts());
    this._monerisAccounts._setUpdateFunction(this._updateMonerisAccounts());

    this._clientsService.clients.onActiveElementChange()
      .subscribe((client: Client) => {
        this._targetClientId = client.clientId;
        this._stripe.update();
        this._monerisAccounts.update();
      });

  }

  public get activeMonerisClientId(): string { return this._activeMonerisClientId };
  public get activeStripeClientId(): string { return this._activeStripeClientId };

  public get stripes() { return this._stripe };

  public get monerisAccounts() { return this._monerisAccounts };
  public get nextMonerisId(): string { return this._lastUsedMonerisId++, "monerisAccount" + this._lastUsedMonerisId; };


  private _addStripe(): (stripe: StripeAccountCreationData) => Observable<any> {
    let self = this;
    return (stripe: StripeAccountCreationData) => {
      debug.log("Adding stripe account:", stripe);
      stripe.clientId = self._targetClientId || self._auth.activeClient?.clientId
      return this._http.post("stripe/add", stripe);
    }

  }

  private _stripeComparison(a: StripeAccount, b: StripeAccount): boolean {

    //Copy the objects
    let stripeA = Object.assign({}, a);
    let stripeB = Object.assign({}, b);

    //Compare the two
    return JSON.stringify(stripeA) === JSON.stringify(stripeB);

  }

  private _saveStripe(): (stripe: StripeAccount) => Observable<any> {
    let self = this;

    return (stripe: StripeAccount) => {

      debug.log("Saving stripe account:", stripe);
      return this._http.post("stripe/update", {
        clientId: self._targetClientId || self._auth.activeClient?.clientId,
        stripeId: stripe.stripeId,
        nickname: stripe.nickname
      });

    }

  }

  private _updateStripes(): (client?: Client) => Observable<StripeAccount[]> {

    let self = this;
    return () => {

      debug.log("Updating stripe accounts");
      let requestData = {
        clientId: self._targetClientId || self._auth.activeClient?.clientId
      }

      return this._http.post("stripe/get", requestData).pipe(
        map((data: APIResponse) => {

          //Copy the clientId
          this._activeStripeClientId = this._targetClientId;

          //Grab the data
          let stripeData: StripeData = data.data;

          //Convert the taxes into an array
          let stripeAccounts: StripeAccount[] = [];
          for (let i in stripeData.stripeAccounts) {
            stripeAccounts.push(stripeData.stripeAccounts[i]);
          }

          // sort by timestamp
          stripeAccounts.sort((leftSide, rightSide): number => {
            if (leftSide.nickname < rightSide.nickname) return 1
            if (leftSide.nickname > rightSide.nickname) return -1
            return 0;
          });

          // return all stripe accounts
          return stripeAccounts;

        }));
    }

  }



  private _monerisAccountComparison(a: MonerisAccount, b: MonerisAccount): boolean {

    //Compare the two
    return a != null && b != null ? a.monerisId === b.monerisId : false;

  }

  private _saveMonerisAccounts(): (garbage: any, options?: any) => Observable<void> {

    let self = this;

    return (garbage: any, options?: any): Observable<void> => {

      //Add moneris
      let monerisAccounts: MonerisAccount[] = self._monerisAccounts.data.value;
      let monerisAccountData: MonerisData = new MonerisData();
      let sharedVaultAccount: MonerisAccount;

      //Prepare the object for storing
      for (let monerisAccount of monerisAccounts) {

        // If the moneris account is the shared vault, copy it.
        if (monerisAccount.isSharedVault) {
          sharedVaultAccount = Objects.copy(monerisAccount)
        }

        delete monerisAccount.storeId;
        delete monerisAccount.checkoutId;

        //Add the moneris account
        monerisAccountData.monerisIds.push(monerisAccount.monerisId);
        monerisAccountData.monerisAccounts[monerisAccount.monerisId] = monerisAccount;

      }

      // If we found a shared vault in the list of moneris accounts
      if (sharedVaultAccount) {
        // Set the data to show that we have a shared vault and the account ID of the shared vault
        monerisAccountData.isSharedVault = true
        monerisAccountData.masterVaultAccount = {
          "accountId": sharedVaultAccount.monerisId,
          "storeId": sharedVaultAccount.storeId,
          "checkoutId": sharedVaultAccount.checkoutId,
        }
      } else {
        // Otherwise, data shows that we don't have a shared vault
        monerisAccountData.isSharedVault = false
      }


      //Set the final used index
      monerisAccountData.lastId = this._lastUsedMonerisId;

      //Prepare the data to send
      let requestData = {
        clientId: self._targetClientId || self._auth.activeClient?.clientId,
        data: monerisAccountData
      };

      //Update or add the item
      return new Observable(observer => {
        this._http.post("moneris/update", requestData).toPromise()
          .then((data: APIResponse) => {
            if (0 == Object.keys(data.data).length || Object.keys(data.errors).length)
              //Add if we get an error
              this._http.post("moneris/add", requestData).toPromise()
                .then((data: APIResponse) => { observer.next(); })
                .catch(error => { observer.next(); })
            else
              observer.next();
          })
          .catch(error => {
            //Add if we get an error
            this._http.post("moneris/add", requestData).toPromise()
              .then((data: APIResponse) => { observer.next(); })
              .catch(error => { observer.next(); })

          })
      })
    }

  }

  private _updateMonerisAccounts(): () => Observable<MonerisAccount[]> {
    let self = this;

    return () => {
      debug.log("Updating moneris accounts");

      return this._http.post("moneris/get", { clientId: self._targetClientId || self._auth.activeClient?.clientId }).pipe(
        map((data: APIResponse) => {

          //Copy the clientId
          this._activeMonerisClientId = this._targetClientId;

          //Convert the data
          let monerisAccountData: MonerisData = !data.errors.length ? data.data : null;

          //Create a new object if nothing was found
          if (!monerisAccountData) monerisAccountData = new MonerisData();

          //Add shared vault store ID to the correct Moneris account
          if (monerisAccountData.masterVaultAccount) {
            monerisAccountData.monerisAccounts[monerisAccountData.masterVaultAccount.accountId].storeId = monerisAccountData.masterVaultAccount.storeId
            monerisAccountData.monerisAccounts[monerisAccountData.masterVaultAccount.accountId].checkoutId = monerisAccountData.masterVaultAccount.checkoutId
          }

          //Convert the kesseltronics Accounts into an array
          let monerisAccounts: MonerisAccount[] = [];
          for (let i of (monerisAccountData.monerisIds || Object.keys(monerisAccounts))) {
            monerisAccounts.push(new MonerisAccount(monerisAccountData.monerisAccounts[i]));
          }

          //Store the last used id
          this._lastUsedMonerisId = "number" == typeof monerisAccountData.lastId ? monerisAccountData.lastId : 0;

          //Sort alphabetically
          monerisAccounts.sort((leftSide: MonerisAccount, rightSide: MonerisAccount): number => {
            if (leftSide.nickname < rightSide.nickname) return -1
            if (leftSide.nickname > rightSide.nickname) return 1
            return 0;
          });

          return monerisAccounts;
        }));
    }
  }

}