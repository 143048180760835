import { version } from './version';

export const environment = {
    production: false,
    ADMIN_URL: '.staging.carwashintelligence.com',
    APP_URL: 'https://staging.api.carwashconnect.ca/',
    APP_VERSION: version,
    API_KEY: 'admin',
    DEBUG: true,
    HEAP_ID: '2228711503',
    STRIPE_CLIENT_ID: 'ca_7dU09gIPxznXCfiEMH0c7ZNCmGmjotr4',
    STRIPE_REDIRECT_URI: 'https://admin.staging.carwashintelligence.com/redirect',
    DASHBOARD_WS_ENDPOINT: 'wss.api.carwashconnect.ca',
};
